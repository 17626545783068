@import '../../css/variables.scss';

.sideNav {
  min-height: 100%;
  background-color: #06203d;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 46.4rem;
  padding-top: 9rem;
  padding-bottom: 4rem;
  transition: width 0.1s linear;
  overflow: hidden;
  z-index: 5;
  grid-area: nav;
  box-shadow: 0.2rem 0 0.5rem rgba(0, 0, 0, 0.1);

  &.fold {
    width: 6.2rem;
    overflow: hidden;
  }
}

.burgerHolder {
  height: 7.5rem;
  padding: 0.5rem 0;
  .sideNav__arrowButton {
    display: flex;
    justify-content: flex-end;
    width: 6.2rem;
    height: 100%;
    border: none;
    background-color: #06203d;
    color: white;
    padding-right: 1rem;
    &:focus {
      outline: 0.2rem solid white;
    }
  }

  .sideNav__burger {
    display: block;
    width: 6.2rem;
    height: 100%;
    border: none;
    background-color: #06203d;
    color: white;
    &:focus {
      outline: 0.2rem solid white;
    }
  }

  svg:first-of-type {
    width: 100%;
    height: 2.5rem;
  }
  svg:last-of-type {
    width: 2rem;
    height: 100%;
  }
}

.sideNav__menu {
  list-style: none;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
  .sideNav__item {
    height: 6.2rem;
    background-color: #0c2d57;
    &.settings {
      margin-bottom: 0.1rem;
    }

    a {
      padding: 0.5rem 0;
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
      align-items: center;
      text-decoration: none;
      color: white;
      border-left: 0.5rem solid transparent;
      &:hover {
        border-left: 0.5rem solid #4b962b;
      }
    }
    button {
      min-width: 6.2rem;
      height: 100%;
      border: none;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -0.5rem;
      padding: 0;
      &:focus {
        outline: 0.2rem solid white;
      }
    }

    svg {
      width: 6.2rem;
      height: 2.5rem;
      color: #4b962b;
    }
    img {
      min-width: 6.2rem;
      height: 2rem;
      transform: translateX(-5px);
    }
  }
}

.sideNav__dropdown {
  flex-grow: 1;
  background-color: #f8f8f8;
  overflow-y: auto;
  width: 0;
  opacity: 0;
  transition: width opacity 0.3s linear;
  height: 0;
  &.open {
    width: 100%;
    opacity: 1;
  }
}
.dropdownHolder {
  display: flex;
  flex-direction: column;
  /* height: 100px; */
  /* overflow-y: scroll; */
}

/* 
Safari fix for my projects section
is set .dropdown-holder to display:flex */

.listItem {
  height: 6.4rem;
  background-color: white;

  margin-bottom: 0.1rem;
  .projectLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #454545;
    height: 100%;
    padding-left: 2.3rem;
    padding-right: 2.3rem;
    font-size: 1.5rem;
    &:hover {
      text-decoration: none;
      color: #2378d4;
    }
  }
  &:hover {
    background-color: rgba(112, 112, 112, 0.07);

    text-decoration: none;
  }
}

@media screen and (max-width: 991px) {
  .sideNav {
    width: 100%;
    transform: translateY(0);
    transition: transform 0.2s linear;
  }
  .sideNav.fold {
    width: 100%;
    transform: translateY(-100%);
  }

  .sideNav__dropdown li {
    height: 4.6rem;
  }
  .sideNav__dropdown li a {
    padding: 0 1.7rem;
  }
  .sideNav__menu .sideNav__item {
    height: 5.8rem;
    img {
      min-width: 6.2rem;
    }
  }
}
