@import '../../css/variables.scss';
.header {
  background-color: #06203d;
  height: 9rem;
  border-bottom: 0.5rem solid #539537;
  padding-left: 4rem;
  padding-right: 4rem;
  position: relative;
  z-index: 10000; 
  grid-area: header;

  .header__logo {
    width: 25rem;
  }

  .header__helpLink {
    display: inline-flex;
    align-items: center;
    margin-right: 3rem;
    text-decoration: underline;
    color: #fff;
  }

  .header__burgerMenu {
    border: none;
    background-color: #06203d;
    outline: none;
    color: white;
    margin-left: 2.2rem;
  }
  .header__burgerMenu:focus {
    outline: none;
  }
  .userIcon {
    margin-right: 1.6rem;
  }
  .userName {
    margin-right: 4.8rem;
  }
  .logoutButton {
    padding: 0;
    background: none;
    border: 0;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    padding-left: 1.7rem;
    padding-right: 1.7rem;

    .header__logo {
      width: 10rem;
    }

    .header__helpLink {
      display: none;
    }
  }
}
