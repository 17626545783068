@import '../../css/variables.scss';

.cardInfo {
  border-radius: 3px;
  border: solid 1px #c7dceb;
  padding: 0.4rem 1.2rem;
  font-weight: $text-bold;
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

p {
  text-emphasis: none;
}
