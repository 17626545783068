@import '../../../../css/variables.scss';
.partyContainer {
  background-color: white;
  overflow: visible;
  padding: 0.3rem 2rem 0 8.2rem;
}

.container {
  width: 20rem;
  height: 20rem;
  position: relative;
  margin: 2rem;
}

.searchComponent {
  margin-top: 2.2rem;
}

.searchComponent > div > input {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(23, 23, 23, 0.18);
  border: solid 0.1rem #06203d;
  border-radius: 0.33rem;
}

.spinner {
  background-color: #e0eef6;
  height: calc(3 * 6.8rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  color: #346480;
}

.partyCard {
  display: flex;
  border: solid 0.1rem #0c203d !important;
  box-shadow: 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 1rem;
  
  .cardTitle {
    background-color: #112d57;
    color: white;
    height: 5.4rem;
    display: flex;
    align-items: center;
    padding: 2rem;
  }

  .cardbody {
    padding: 0.2rem;
  }

  .icon {
    height: 1.58rem;
    width: 2.4rem;
    margin-right: 1rem;
  }

  .cardItems {
    padding: 1.1rem 2rem 1.1rem 2rem;
    display: flex;
  }

  .cardLabels {
    margin-right: 0.5rem;

    width: 4.1rem;
    height: 2rem;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #454545;
  }

  .cardAddressLabel {
    margin-right: 0.5rem;
    width: 4.1rem;
    height: 2rem;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #454545;
  }

  .cardAddressContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .cardValues {
    width: 4.1rem;

    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #454545;
  }

  .cardAddressValue {
    height: 2rem;
    font-size: 1.5rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #454545;
    width: 23.7rem !important;
  }
}

@media (max-width: 991px) {
  .partyContainer {
    padding-left: 2rem;
    width: 45.3rem;
  }
}

@media (max-width: 495px) {
  .spinner {
    width: 100%;
  }
  .partyContainer {
    width: 100%;
  }

  .partyCard {
    width: 100%;

    .cardAddressValue {
      width: 100%;
    }
    .cardAddressLabel {
      width: 15.4rem !important;
    }
  }

  .searchComponent {
    width: 100%;
  }
}

@media (max-width: 360px) {
  .partyCard {
    .cardAddressLabel {
      width: 16.5rem !important;
    }
  }
}

@media (max-width: 300px) {
  .partyCard {
    .cardAddressValue {
      width: 48%;
    }
    .cardAddressLabel {
      width: 10rem !important;
    }
  }
}

@media (max-width: 575px) {
  .spinner {
    width: 100%;
  }
}
