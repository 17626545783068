@import '../../../../css/variables.scss';

.TooltipInfo {
  height: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #454545;
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: solid underline;
  margin-left: 3.25rem;
}

.popoverBlockContainer {
  max-width: 50rem !important;
  width: 45rem;
  box-shadow: 0 0.1rem 0.4rem 0 rgba(44, 64, 90, 0.21) !important;
  border: solid 0.1rem rgba(183, 210, 229, 0.8) !important;
  background-color: #ffffff !important;
  left: 4.8rem !important;
  max-height: calc(100% - 7.5rem);
  display: flex !important;
  flex-direction: column;
}

.titleContainer {
  padding: 1.6rem 1.9rem !important;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  border-bottom: solid 0.1rem rgba(183, 210, 229, 0.8) !important;
  background-color: #e4eef5 !important;

  .button {
    border: none !important;
    background-color: #e4eef5;
    margin-right: -0.8rem;
  }
}

.title {
  width: 19.8rem !important;
  height: 2.2rem !important;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.56 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #0c203d !important;
  font-family: Open Sans !important;
  flex: 1;
}
.popover_content {
  overflow-y: auto;
}

.addressTitles {
  font-family: Open Sans !important;
  height: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #454545;
}

.modalCurrentAddressesContainer {
  border-bottom: solid 0.1rem #bfd7e8 !important;
  padding: 1.5rem 0;
}

.modalCurrentAddressesContainer {
  padding: 1.5rem 0;
}

.modalHistoricalAddressesContainer {
  margin-top: 1.5rem;
}

.popoverCurrentAddressesContainer {
  border-bottom: solid 0.1rem #bfd7e8;
}

.popoverHistoricalAddressesContainer {
  padding: 1.4rem 0 0 0;
}

.popoverAddressSection {
  font-family: Open Sans !important;
  padding: 1rem 0 1.5rem 0;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #454545;
}

.popoverAddressesContainer {
  padding: 0 0.2rem 0 2.1rem;
}

.modalAddressesContainer {
  padding: 0.5rem 0 0 1.4rem;
}

.modalAddressesContainer > div:nth-child(n + 2) {
  border-top: dashed 0.1rem #bfd7e8;
}

.popoverAddressesContainer > div:nth-child(n + 2) {
  border-top: dashed 0.1rem #bfd7e8;
}

.modalAddressSection {
  padding: 0.5rem 0;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #454545;
}

.modalDoc {
  width: 100%;
  align-items: stretch !important;
  margin: 0 !important;
  min-height: 100% !important;
  padding: 0 !important;

  div {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0;
  }
}

.modalHeaderButton {
  button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1000;
    font-size: 2.2rem;
  }
}

.modalContainer {
  padding: 2.2rem 1.7rem;
}

.popoverContainer {
  padding: 0.7rem 0.8rem;
}

@media (max-width: 907px) {
  .modalDoc {
    max-width: 90rem !important;
  }
}
