@import '../../css/variables.scss';

.cardButton {
  text-decoration: underline;
  border: none;
  background-color: transparent;
  font-weight: $text-bold;
}

.grey {
  color: #454545;
}
