.Container {
  display: inline-flex;
  flex-direction: column;
  z-index: 3;
  width: 44.3rem;
  height: 100%;
  pointer-events: all;

  &.fixedHeight {
    bottom: 12rem;
  }

  @media (max-width: 768px) {
    width: 100%;

    &.isSearching {
      padding: 2.4rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fafbfc;
      z-index: 1000;
    }

    &.fixedHeight {
      bottom: 16rem;
    }

    .hasResults {
      background-color: #fafbfc;
    }
  }
}

@media (max-width: 991px) {
  .SignPost {
    width: 100%;
    margin-left: 0rem;
    position: absolute;
    z-index: 3;
  }
}
