.infoButton {
  border: 0;
  background-color: transparent;
  color: #454545;
  text-decoration: underline;
  font-weight: bold;
}

//Popover Styles
.infoPanelHeader {
  background-color: #e4eef5 !important;
  font-size: 1.6rem !important;
  font-weight: 600;
  color: #0c203d;
  padding: 1.5rem 2rem !important;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 0.1rem rgba(183, 210, 229, 0.8);
  .closeButton {
    background-color: transparent;
    border: 0;
    color: currentColor;
    padding: 0;
  }
}

.infoPanelContainer {
  min-width: 45rem;
  max-width: 50rem;
  left: 4.8rem !important;
  border-radius: 0.5rem !important;
  border: solid 0.1rem rgba(183, 210, 229, 0.8) !important;
  box-shadow: 0 0.1rem 0.4rem 0 rgba(44, 64, 90, 0.21);
  font-family: 'Open Sans', sans-serif;
  max-height: calc(100% - 15rem);
  display: flex !important;
  flex-direction: column;
  // z-index: 5;
  .infoPanelBody {
    overflow-y: auto;
    // height: 200px;
    padding: 1.5rem 3rem;
    font-size: 1.5rem;
    .panelEntry {
      border-bottom: 0.1rem solid #ddeaf3;
      padding: 1.5rem 0;

      h2 {
        font-size: 1.5rem;
        font-weight: 600;
      }

      &:first-of-type {
        padding: 0;
      }
      &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
      }

      .entryIndentation {
        padding: 1rem 1.5rem;
        .infoWrapper {
          &:first-of-type {
            padding-top: 0;
          }
          &:last-of-type {
            padding-bottom: 0;
          }
          &:not(:first-of-type) {
            border-top: 0.1rem dashed #cddfec;
          }
          padding: 1.5rem 0;
        }

        a {
          text-decoration: underline;
          color: #2b72ca;
          font-weight: 600;
          margin-right: 0.5rem;
          white-space: normal;
          display: inline;
        }
      }
    }
  }
}

//Modal Styles

.infoModalContainer {
  width: 100%;
  align-items: stretch !important;
  margin: 0 !important;
  min-height: 100% !important;
  padding: 0 !important;
  font-size: 1.5rem;
  max-width: unset !important;

  .infoModalBody {
    padding: 0 3rem;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    .SupportingEvidencesList {
      word-wrap: break-word;
    }
  }

  .infoModalHeader {
    margin-top: 7rem;
    padding: 0 3rem;
    font-size: 1.6rem;
    font-weight: 600;
    border-bottom: 0;
    button {
      position: absolute;
      right: 2rem;
      top: 1.5rem;
      font-size: 4rem;
    }
  }
  .panelEntry {
    border-bottom: 0.1rem solid #ddeaf3;
    padding: 1.5rem 0;

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    &:first-of-type {
      padding: 0;
    }
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }

    .entryIndentation {
      padding: 1rem 1.5rem;
      .infoWrapper {
        &:first-of-type {
          padding-top: 0;
        }
        &:last-of-type {
          padding-bottom: 0;
        }
        &:not(:first-of-type) {
          border-top: 0.1rem dashed #cddfec;
        }
        padding: 1.5rem 0;
      }

      a {
        text-decoration: underline;
        color: #2b72ca;
        font-weight: 600;
        margin-right: 0.5rem;
        white-space: normal;
        display: inline;
      }
    }
  }
}

.infoModalContent {
  min-height: 100vh !important;
  overflow-y: auto;
}
