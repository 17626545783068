@import '../../css/variables.scss';

.cardContainer {
  border-radius: 4px;
  border: solid 0.1rem #0c203d !important;
  margin-bottom: 1rem;

  &:nth-child(2n) {
    background-color: #f7f7f7;
  }

  &.grey {
    border: solid 0.1rem rgba(183, 210, 229, 0.8) !important;
  }

  .cardTitle {
    height: 5.3rem;
    color: white;
    background-color: #112d57;
    display: flex;
    align-items: center;
    padding: 1.6rem;

    .icon {
      margin-right: 1.4rem;
      padding: 0;
    }
  }

  .cardBody {
    padding: 0;
    font-size: 1.5rem;
    color: $text-color;

    .listItem {
      background-color: transparent;
      padding: 1.6rem;

      &:nth-last-of-type(1) {
        border-bottom: none !important;
      }
      .listLabel {
        font-weight: $text-bold;
      }
    }
  }

  .buttonsContainer {
    background-color: transparent;
    padding: 0 1.6rem 1.6rem;
    display: flex;
    border: none;
    justify-content: space-between;

    &.hasTwoButtons {
      :first-child {
        margin-right: 8px;
      }

      :last-child {
        margin-left: 8px;
      }

      > button,
      > a {
        flex: 1;
      }
    }
  }
}

@media (max-width: 768px) {
  .cardContainer {
    .buttonsContainer {
      flex-direction: column;

      &.hasTwoButtons {
        :first-child {
          margin-right: 0;
        }
        :last-child {
          margin-left: 0;
        }
      }

      &.hasTwoButtons {
        & > {
          margin-left: 0;
          margin-right: 0;

          :first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
