@import '../../css/variables.scss';
.projectCard {
  border-radius: 0.5rem;
  border: solid 0.1rem #d8e5ee;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.16);
  &:hover {
    border: 1px solid black;
    color: currentColor;
  }
  .projectBody {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .projectTitle {
    font-size: 2.6rem;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0c203d;
  }
  .projectClient {
    color: #454545;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  .lastUpdated {
    font-size: 1.4rem;
    color: #454545;
    font-weight: 600;
  }
  .ellipse {
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;

    &.active {
      background-color: #4c723e;
    }

    &.inActive {
      background-color: #707070;
    }

    &.onHold {
      background-color: #b14e0c;
    }
  }
  .map {
    text-align: center;
    margin-top: auto;
  }
  .mapImage {
    width: 100%;
    height: 13.7rem;
    object-fit: cover;
    border-radius: 0.4rem;
    border: 1px solid #d8e5ee;
  }
}
.projectDropdown {
  position: absolute;
  z-index: 3;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
}
.projectLink {
  &:hover {
    text-decoration: none;
  }
}

.dropdownToggle {
  background-color: transparent;
  font-size: 3rem;
}
.dropdownToggle::after {
  display: none !important;
}

.dropdownMenu {
  background-color: transparent !important;
  font-size: 1.6rem;
  padding: 0 !important;
  box-shadow: 0 0.1rem 0.4rem 0 rgba(44, 64, 90, 0.21);
  position: relative;
  border: 0;
  border-radius: 0.5rem !important;
  cursor: default;

  &:last-child {
    border-radius: 0.5rem 0.5rem 0 0;
  }
  &:first-child {
    border-radius: 0 0 0.5rem 0.5rem;
  }

  &::before {
    content: ' ';
    width: 1rem;
    height: 1rem;
    position: absolute;
    z-index: -2;

    top: -1rem;
    border-bottom: 2rem solid #112d57;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    right: 0;
  }

  .dropdownItem {
    color: white;
    padding: 1rem 1.5rem;
    background-color: #112d57;
    font-size: 1.5rem;

    &:global(.disabled) {
      cursor: default;
      color: #adadad;
      pointer-events: auto;
      background-color: #112d57 !important;
      &:hover {
        color: #adadad;
        background-color: #112d57;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 0.1rem solid rgba(183, 210, 229, 0.8);
    }

    &:first-of-type {
      border-radius: 0.5rem 0.5rem 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 0.5rem 0.5rem;
    }

    &:hover {
      background-color: #f3f4f4;
      color: #2c78d4;
    }
  }
}
