@import '../../css/variables.scss';

.title {
  font-size: 2rem;
  color: $primary-color;
}

.copyright {
  font-size: 1.3rem;
}

.footer {
  display: flex;
  align-items: center;
  background-color: #161615;
  color: #cccccc;
  position: relative;
  z-index: 10000;
  padding: 0 14rem;

  grid-area: footer;

  span {
    color: #5bb94c;
  }

  .footerLink {
    font-size: 1.4rem;
    font-weight: 700;
    text-decoration: underline;
    color: #cccccc;
    font-weight: bold;

    &:first-of-type {
      margin-right: 4rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 0 1.7rem;

    .copyright {
      font-size: 1.4rem;
    }

    .footerLink:first-of-type {
      margin-right: 1.5rem;
    }
  }
}
