//All global variables to be used within other sass files
$primary-color: #112d57;
$secondary-color: #48842f;
$text-color: #454545;
$text-bold: 600;
$button-blue: #2c78d4;

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xlg: 1600px,
);
